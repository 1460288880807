.information {
    padding-top: 15px;
    margin-bottom: 250px;
    .row {
        margin: 0;
        h1 {
            @include Medium;
            font-size: 40px;
            margin-bottom: 37px; }
        p, li {
            @include Regular;
            font-size: 13px;
            line-height: 21px;
            color: #343434;
            margin: 0;
            margin-bottom: 20px; }
        h2 {
            @include Medium;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px; }
        ul {
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
            li {
                list-style: none;
                padding: 0;
                margin-bottom: 5px; } } } }

@media screen and ( max-width: 480px ) {
    .information {
        margin-bottom: 50px; } }

