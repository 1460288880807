@import "scss_lib/smart-grid";
@import "scss_lib/font-awesome";
@import "fonts";
@import 'interface';
@import 'blocks/main-slider';
@import 'blocks/actual';
@import 'blocks/breadcrumbs';
@import 'blocks/not-found';
@import 'blocks/not-found-cart';
@import 'blocks/blog-page';
@import 'blocks/contacts';
@import 'blocks/information';
@import 'blocks/story';


$transition: 0.2s linear all;
$blueMain: #2E56BF;
$blueDark: #0a2670;
$bluerDoubleDark: #06184a;
$black: #343434;
$white: #ffffff;


$goldLight: #fff3dd;
$goldMain: #F5D69F;
$goldDark: #ebbf71;


$greyLight: #f8f8f8;
$greyDark: #a0a0a0;
$greyMain: #eeeeee;


* {
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

img {
  border-style: none;
  max-width: 100%;
  width: auto;
  height: auto;
}

body {
  margin: 0;
  background-color: #fafaff;


  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}

a {
  display: inline-block;
  text-decoration: none;
}

.row {
  @include row-flex;
}

.container {
  @include wrapper;
  width: 100%;
}

.spanText {
  font-family: "TT Norms Pro";

  &--bold {
    color: #343434;
    font-size: 16px;
    font-weight: 500
  }

  &--light {
    color: $greyDark;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }
}

.mainHeader {
  background-color: #f8f8f8;
  position: sticky;
  top: 0px;
  z-index: 11;

  &__info {
    min-height: 50px;
    align-items: center;

    &__address {
      justify-content: start;
    }

    &__phones {
      justify-content: center;

      .spanText--light {
        cursor: pointer;
        position: relative;

        &:after {
          transition: $transition;
          content: '';
          position: absolute;
          width: 0px;
          height: 1px;
          background: $greyDark;

          bottom: 0px;
          transform: translatex(-50%);
          left: 50%;
        }

        &:hover {
          &:after {
            width: calc(100%);
          }
        }
      }

      @include sm-block{
        .spanText{
          display: block;
        }

      }
    }

    &__timeWorks {
      justify-content: flex-end;
      @include sm-block{
        .spanText{
          display: block;
        }
      }
    }

    > div {
      @include col;
      @include size(4);
      display: flex;
      padding: 4px 0;
      flex-wrap: wrap;
      @include size-sm(12);
      @include sm-block {
        justify-content: flex-end;

      }
    }

    .spanText--light {
      margin-left: 25px;
    }

    &__phones {
      .spanText--light {
        cursor: pointer;
      }
    }
  }

  @include sm-block {
    display: none;
  }
}

.wrapGam {
  .navGam {
    cursor: pointer;
    display: none;
    @include sm-block {
      padding: 0px;
      display: block;
      width: 36px;
      border: none;
      background: transparent;
      span {
        display: block;
        height: 2px;
        border-radius: 2px;
        width: 100%;
        background: $black;
        margin-bottom: 5px;
        &:last-child{
          margin-bottom: none;
        }
      }
    }
  }

  .navList {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    li {
      a {
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $black;
        position: relative;
        padding: 8px 8px;
        @include md-block(){
          padding: 8px 3px;
        }
        white-space: pre-wrap;

        &:after {
          transition: $transition;
          content: '';
          position: absolute;
          width: 0px;
          height: 1px;
          background: $black;
          bottom: 3px;
          transform: translatex(-50%);
          left: 50%;
        }

        &:hover {
          &:after {
            width: calc(100% - 16px);
          }
        }
      }

      .dropdown {
        display: inline-block;
        cursor: pointer;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $black;
        position: relative;
        padding: 8px;
        @include md-block(){
          padding: 8px 3px;
        }
        i {
          transition: $transition;
          margin-left: 5px;
        }

        &:after {
          transition: $transition;
          content: '';
          position: absolute;
          width: 0px;
          height: 1px;
          background: $black;
          bottom: 3px;
          transform: translatex(-50%);
          left: 50%;
        }

        &:hover {
          &:after {
            width: calc(100% - 16px);
          }

          i {
            transform: rotate(180deg);
          }

          ul {
            padding: 0;
            list-style: none;
            width: max-content;
            left: 5px;
            top: 89%;
            display: flex;
            li{
              a{
                padding: 3px;
                margin-right: 5px;
                &:hover{
                  &:after {
                    width: calc(100% - 8px);
                  }
                }
              }
            }
          }
        }

        ul {
          display: none;
          position: absolute;
        }

      }

    }

    @include sm-block {
      display: none;
      //li {
      //  text-align: right;
      //  width: 100%;
      //
      //  a {
      //    padding: 8px 0 8px 8px;
      //    display: inline-block;
      //
      //    &:hover {
      //      &:after {
      //        width: calc(100% - 0px);
      //      }
      //    }
      //  }
      //}
      //display: none;

    }
  }

  @include md-block {
    //text-align: right;
    //display: flex;
    //flex-direction: column;
    //align-items: flex-end;
  }
}

.common-home {
  //.mainHeader, .nav {
  //  position: relative;
  //  top: 0;
  //  box-shadow: none !important;
  //}
  //
  .nav {
    background-color: transparent;

    &.scroll {
      background-color: $white;

      .wrapGam {
        .navGam {
          @include sm-block {
            span {
              background: $black;
            }
          }
        }

        .navList {
          li {
            a {
              color: $black;

              &:after {
                background: $black;
              }
            }

            .dropdown {
              color: $black;

              i {
              }

              &:after {
                background: $black;
              }
            }
          }
        }
      }
    }
  }

  .wrapGam {
    .navGam {
      @include sm-block {
        span {
          background: $white;
        }
      }
    }

    .navList {
      li {
        a {
          color: $white;

          &:after {
            background: $white;
          }
        }

        .dropdown {
          color: $white;

          i {
          }

          &:after {
            background: $white;
          }
        }
      }
    }
  }


}


.nav {
  @include sm-block{
    padding: 0px 0px 0px 0px;
  }
  padding: 0px 0px 5px 0px;
  position: sticky;
  z-index: 100;
  width: 100%;
  top: 50px;
  z-index: 10;
  background: #fff;
  transition: $transition;

  &.scroll {
    z-index: 100 !important;
    box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.25)
  }

  @include sm-block {
    position: fixed;
    top: 0px;
  }
}

.navigation {
  align-items: center;

  &__logo {
    @include col;
    //@include size(1);
    @include u-size(5, 60, 30px);

    @include from-to($break_sm,$break_md){
      @include u-size(6, 60, 10px);
      margin-right: 5px;
      margin-left: 5px;
    }



    @include size-sm(4);
    @include sm-block {

      img{
        height: 70px;
      }
      order: 1;



      justify-content: center;
      display: flex;
    }

    a {
      height: max-content;

      img {
        display: block;
        max-height: 55px;
      }
    }
  }

  &__menu {
    @include col;
    //@include size(10);
    @include u-size(50, 60, 30px);

    @include from-to($break_sm,$break_md){
      @include u-size(54, 60, 10px);
      margin-right: 5px;
      margin-left: 5px;
    }
    @include size-sm(4);
    @include sm-block {
      order: 0;
      justify-content: flex-start;

    }
    //@include md-block {
    //  align-self: flex-end;
    //}
  }

  &__cart {
    @include col;
    //@include size(1);
    @include u-size(5, 60, 30px);



    @include size-md(2);





    @include shift-md-left(10);
    @include size-sm(4);
    @include shift-sm-left(0);


    display: flex;
    //justify-content: center;
    justify-content: flex-end;
    @include md-block {
      justify-content: flex-end;
    }
    @include sm-block {
      order: 2;
    }
  }

  @include sm-block {

  }
}


#cart {
  transition: $transition;
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 45px;
  background-color: $blueDark;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 8px;

  &__count {
    transition: $transition;
    background-color: $blueMain;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    color: $white;
    font-family: "TT Norms Pro";
    font-size: 8px;
    font-weight: 500;
    text-align: center;
    line-height: 15px;
    position: absolute;
    right: -8px;
  }

  &:hover {
    background-color: $blueMain;
  }

  &:hover & {
    &__count {
      background-color: $blueDark;
    }
  }

  &:active {
    background-color: $bluerDoubleDark;
  }

  &:active & {
    &__count {
      background-color: $blueMain;
    }
  }

  position: relative;

  #alertCart {
    position: absolute;
    padding: 20px;
    background: $blueDark;
    width: 200px;
    right: 45px;
    border-radius: 10px;
    font-size: 13px;
    font-weight: 1.2;
    box-shadow: 3px 6px 8px 0px $blueDark;
  }
}

h1, h2, h3, h4, h5 {
  margin: 0px;
}

.sectionTitle {
  margin-top: 55px;
  margin-bottom: 40px;
  @include col;
  @include size(12);
  @include size-sm(12);
  @include sm-block {
    margin-top: 35px;
    margin-bottom: 20px;
  }

}

.customInput {
  margin-bottom: 12px;
  display: flex;
  align-items: center;


  input {
    + label {
      display: flex;
      cursor: pointer;
      margin-right: 14px;

      svg {
        width: 20px;
        height: 20px;
        flex: 0 0 auto;
      }

      span {
        margin-left: 15px;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $black;
      }
    }
  }

  [type="checkbox"] {
    + label {
      svg {
        > rect {
          transition: $transition;
          fill: $white;
          stroke: $greyDark
        }

        > path {
          display: none
        }
      }

      &:hover {
        svg {
          > rect {
            fill: $white;
            stroke: $blueDark
          }
        }

      }
    }

    &:checked {
      + label {
        svg {
          > rect {
            transition: $transition;
            fill: $blueDark;
            stroke: $blueDark
          }

          > path {
            display: block
          }
        }

        &:hover {
          svg {
            > rect {
              fill: $blueMain;
              stroke: $blueMain;
            }
          }

        }
      }
    }

  }

  [type="radio"] {
    + label {
      svg {
        > rect {
          transition: $transition;
          fill: $white;
          stroke: $greyDark;
        }

        circle {
          display: none;
        }
      }

      &:hover {
        svg {
          > rect {
            fill: $white;
            stroke: $blueDark
          }
        }

      }
    }

    //////////////////////////////////////////////////////////////////////
    &:checked {
      + label {
        svg {
          > rect {
            transition: $transition;
            stroke: $blueDark;
            fill: $blueDark;
          }

          > circle {
            display: block
          }
        }

        &:hover {
          svg {
            > rect {
              stroke: $blueMain;
            }
          }

        }
      }
    }


    &.checkbox {
      + label {
        svg {
          > rect {
            transition: $transition;
            fill: $white;
            stroke: $greyDark
          }

          > path {
            display: none
          }
        }

        &:hover {
          svg {
            > rect {
              fill: $white;
              stroke: $blueDark
            }
          }

        }
      }

      &:checked {
        + label {
          svg {
            > rect {
              transition: $transition;
              fill: $blueDark;
              stroke: $blueDark
            }

            > path {
              display: block
            }
          }

          &:hover {
            svg {
              > rect {
                fill: $blueMain;
                stroke: $blueMain;
              }
            }

          }
        }
      }
    }
  }


}

.title {
  display: inline-block;
  font-family: "TT Norms Pro";
  font-style: normal;
  color: $black;
  font-weight: normal;

  &--filter {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span:last-child {
      i {
        transition: $transition;

        &.open {
          transform: rotate(180deg);
        }
      }
    }

  }

  &--center {
    text-align: center;
  }

  &--h1 {
    font-size: 60px;
    line-height: 72px;
  }

  &--h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
  }

  &--h3 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
  }

  &--h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  &--h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }

  &--white {
    color: $white;
  }

  @include sm-block {
    &--h1 {
      font-size: 26px;
      line-height: 1.2;
      font-weight: 500;
    }

    &--h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 1.2;
    }

    &--h3 {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 500;
    }

    &--h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
    }

    &--h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 1.2;
    }


  }

}

.homeSliderTitle {margin-top: 30px;
  @include col;
  @include size(10);
  @include shift(1);
  @include size-lg(12);
  @include shift-lg(0);
  @include size-sm(12);
  text-align: center;


}

.homeSliderSubTitle {
  padding: 0 51px;
  @include col;
  @include size(8);
  @include shift(2);
  @include size-lg(10);
  @include shift-lg(1);
  @include size-md(12);
  @include shift-md(0);
  @include size-sm(12);
  @include sm-block {
    padding: 0;
  }

  .title {
    margin-top: 27px;
  }
}

.button {
  display: inline-flex;
  padding: 14px 37px 13px 37px;
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border-radius: 5px;
  transition: $transition;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &--accentBlue {
    color: $white;
    background: $blueDark;

    &:hover {
      background: $blueMain;

    }

    &:active {
      background: $bluerDoubleDark;
    }
  }

  &--accentGold {
    color: $white;
    background: $goldMain;

    &:hover {
      color: $goldDark;
      background: $goldLight;
    }

    &:active {
      color: $white;
      background: $goldDark;
    }
  }

  &--border {
    border: 2px solid $blueDark;
    box-sizing: border-box;

    &:hover {
      border-color: $blueMain;
    }

    &:active {
      border-color: $blueDark;
    }
  }

  &--default {
    background: $white;
    color: $blueDark;

    &:hover {
      background: $blueMain;
      color: $white;
    }

    &:active {
      background: $bluerDoubleDark;
    }
  }

  &--disabled {
    background: $greyMain !important;
    color: $greyDark !important;
    cursor: not-allowed;
  }

}


#mainSlider {
  padding-top: 109px;
  margin-top: -131px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/catalog/view/theme/default/production/img/bgSlide.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include sm-block {
    margin-top: 0px;
    padding-top: 65px;
  }

  .wrapSlider {
    position: relative;
  }

  .tryCoffee {
    bottom: 0px;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h4 {
      max-width: 154px;
      text-align: right;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
    }

    img {
      width: 43px;
      margin-right: 0;
      margin-left: 22px;
    }

    @include sm-block {
      display: none;
    }
  }
  padding-bottom: 42px;
}

.blockCatalog {
  @include col;
  @include size(6);
  @include size-lg(12);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 290px 93px 50px 43px;
  background-size: cover !important;
  @include size-sm(12);

  a {
    margin-top: 44px;
  }

  @include lg-block {
    padding: 190px 93px 50px 43px;
    .title {
      margin-right: 25px;
    }
    a {
      margin-bottom: 44px;
    }
  }
  @include sm-block {
    padding: 90px 93px 50px 43px;
    a {
      margin-bottom: 24px;
      margin-top: 24px;
    }
  }
}

.blockСasket {
  @include col;
  @include size(6);
  @include size-lg(12);
  @include size-sm(12);
  display: flex;
  flex-direction: column;

  .wrapper {
    background: $greyLight;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 25px 34px 0px;

    .wrapButton {
      margin-left: 27px;

      a {
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }

    @include lg-block {
      .title {
        margin-right: 25px;
      }
      margin: 15px 0;
    }
    @include sm-block {
      flex-wrap: wrap;
    }
  }

  .tryCoffee {
    margin-top: auto;
    padding: 79px 101px 67px 101px;
    background: url('/catalog/view/theme/default/production/img/coffee1.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        margin-left: 29px;
      }
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}


.coffeeInfo {
  .container {
    .wrapperCoffeeInfo {
      @include col;
      @include size(12);
      @include size-sm(12);
      padding-top: 84px;
      padding-bottom: 144px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("/catalog/view/theme/default/production/img/bgText.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @include sm-block {
        padding-top: 25px;
        padding-bottom: 25px
      }

      .wrappTitle {
        @include col;
        @include size(7);
        @include shift-left(1);
        @include size-lg(11);
        @include shift-lg-left(1);
        @include size-sm(10);
        @include shift-sm-left(1);
        @include sm-block {
          text-align: left;
        }
      }

      .wtapperText {
        margin-top: 87px;
        @include sm-block {
          margin-top: 25px;
          .wrappInfo{
            &:last-child{
              display: none;
            }
          }
        }

        .wrappInfo {
          @include col;
          @include size(5);
          @include shift-left(1);
          @include size-md(4);
          @include shift-md(1);
          @include size-sm(10);
          @include shift-sm(1);

          &--col2 {
            padding-right: 65px;
            @include sm-block {
              margin-top: 15px;
              padding-right: 0px;
            }
          }
        }

      }
    }
  }
}

.textBody {
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  color: $black;

  &--white {
    color: $white;
  }

  //&--grey {
  //  color: $greyDark;
  //}

}

.blog {
  .sectionTitle{
    display: flex;
    justify-content: space-between;
  }
  .linkArticles {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    a {
      position: relative;

      &:after {
        transition: $transition;
        content: '';
        position: absolute;
        width: 0px;
        height: 1px;
        background: $black;
        bottom: 0px;
        transform: translatex(-50%);
        left: 50%;
      }

      &:hover {
        &:after {
          width: calc(100%);
        }
      }
    }
  }

}

.blogItem {
  @include col;
  @include size(4);
  @include size-sm(12);

}

.article {
  display: flex;
  flex-direction: column;

  &__img {
    width: 100%;
    height: 257px;
    object-fit: cover;
    margin-bottom: 18px;
  }

  &__time {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: $greyDark;
    margin-bottom: 18px;
  }

  &__title {
    transition: $transition;
    margin-bottom: 23px;
    color: $black;
  }

  &:hover {
    .article__title {
      color: $blueDark;
    }
  }

  &:active {
    .article__title {
      color: $blueMain;
    }
  }

}

.footerWrapLogo {
  @include col;
  @include size(6);
  @include size-sm(12);
}

.footerWraperLogoInfo {
  @include col;
  @include size(12);
  display: flex;
  padding-right: 125px;

  > a {
    margin-right: 35px;
    img{
      width: 77px;
    }
  }

  > div {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    color: $black;
  }

  @include lg-block {
    padding-right: 50px;
  }
  @include sm-block {
    padding-right: 0px;
    align-items: center;
    justify-content: space-between;
    > div {
    }
  }
}

.footerWrapPhones, .footerWrapEmail {
  margin-top: 45px;
  @include col;
  @include size(4);
  @include size-lg(6);
  @include size-sm(6);

  span {
    display: block;
    margin-bottom: 5px;
  }
}

@include sm-block {
  .footerWrapEmail {
    text-align: left;
  }
}

footer {
  padding-bottom: 25px;
  padding-top: 52px;
  background: #fff;
  @include sm-block {
    padding-bottom: 25px;
    padding-top: 25px;
  }
}

.footerLinks {
  @include col;
  @include size(2);
  @include size-sm(6);

  .titleLink {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: $black;
    margin-bottom: 25px;
  }

  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;

    li {
      a {
        color: $black;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        padding-top: 3px;
        padding-bottom: 3px;
        position: relative;

        &:after {
          transition: $transition;
          content: '';
          position: absolute;
          width: 0px;
          height: 1px;
          background: $black;
          bottom: 0px;
          transform: translatex(-50%);
          left: 50%;
        }

        &:hover {
          &:after {
            width: calc(100%);
          }
        }
      }
    }
  }

  .tryCoffee {
    display: block;

    div {
      margin-top: 7px;
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
    }
  }

  &:last-child {
    @include shift-sm-left(3)
  }
}

.top {
  .fullfidt {
    @include sm-block {
      padding: 0;
    }
  }
  .wrapTopTitle{
    @include col;
    @include size(12);

    @include sm-block{

      @include u-size(12,12,0px);
      margin: 0px;

    }

  }

  .tryCoffee {
    .textBody {
      @include sm-block {
        max-width: 205px;
      }
    }
  }

}

.topTitle {
  width: 100%;
  position: relative;
  padding: 33px 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 47px;

  .title--h2 {
    max-width: 557px;
  }

  .title--h5 {
    margin-top: 24px;
    max-width: 606px;
  }

  .tryCoffee {
    width: 155px;
    position: absolute;
    right: 41px;
    bottom: 25px;
  }

  @include sm-block {
    padding: 10px 13px;
    .tryCoffee {
      position: relative;
      width: 100%;
      right: 0px;
      bottom: 0px;
      margin-top: 24px;
      margin-bottom: 10px;

      a {
        display: flex;
        justify-content: flex-end;

        img {
          width: 45px;
          margin-right: 15px;
        }
      }
    }
  }

}

#filter {

  .button {
    width: calc(100% - 45px);
    margin-top: 19px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.wrapProducts {
  .filter {
    @include col;
    @include size(3);
    @include size-lg(4);
    @include size-sm(0);
    @include sm-block {
      #filter {
        display: none;

      }
    }
  }

  .productItems {
    @include col;
    @include size(9);
    @include size-lg(8);
    @include size-sm(12);

    .wrapSort {
      @include col;
      @include size(12);
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
      flex-wrap: wrap;

    }


    .product {
      @include col;
      @include size(4) ;
      @include size-lg(6) ;
      @include size-sm(12) ;

      &--box {
        @include size(12) ;
        @include size-sm(12) ;
      }


    }

    .wrapSortFilter {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;

      > .button {
        display: none;
        @include sm-block {
          display: block;
        }
      }

      .wrapSort {
        .sortText {
          margin-right: 54px;
          @include lg-block {
            margin-right: 5%;
          }
          @include md-block {
            width: 100%;
            margin-bottom: 15px;
          }


        }

        .sortVar {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .sortBy {
            margin-right: 8px;

            color: $greyDark;
            cursor: pointer;
            transition: $transition;

            &:hover {
              color: $blueDark;
              font-size: 14px;
              font-weight: 500;
            }

            &--active {
              color: $blueDark;
              font-size: 14px;
              font-weight: 500;
            }


          }
        }

        @include sm-block {
          display: none;
        }


      }

      @include sm-block {
        margin-bottom: 30px;
        justify-content: flex-end;
        > .button {
          width: 100%;
        }
      }

    }
  }

}

.product {
  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  &:active {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
background: #fff;
  transition: $transition;
  $product: &;
  display: flex;
  flex-direction: column;
  color: $black;
  font-family: TT Norms Pro;
  font-style: normal;
  margin-bottom: 40px;

  &__img {
    width: 100%;
    margin-bottom: 23px;
    object-fit: contain;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    height: 50px;
    overflow: hidden;
    margin-bottom: 14px;
    text-overflow: ellipsis;
    padding: 0 16px;
  }

  &__description {
    font-family: TT Norms Pro;
    font-weight: normal;
    font-size: 13px;
    line-height: 21px;
    padding: 0 16px;
    margin-bottom: 32px;
  }

  &__control {
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    padding: 21px 22px;
    border-radius: 0px;
    margin-left: 24px;
  }

  &--box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;


    .wrapProductText {
      padding-left: 33px;
    }

    img {
      max-width: 255px;
      height: auto;
      max-height: 249px;
      object-fit: cover;
    }

    #{$product}__title {
      text-align: left;
      color: #000;
      padding: 0;
      font-size: 20px;
    }

    #{$product}__description {
      text-align: left;
      color: #000;
      padding: 0;
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
    }

    #{$product}__control {
      justify-content: flex-start;

      .price__from {
        width: 170px;
        margin-right: 11px;
        font-size: 30px;
        font-weight: 500;
        line-height: 40px;
      }

      .button {
      }
    }

    @include sm-block {
      flex-wrap: wrap;
      img {
        max-width: 100%;
        max-height: 100%
      }
      .wrapProductText {
        padding: 0;
      }
      .product__title {
        text-align: center;
      }
      .product__description {
        text-align: center;
      }
      .product__control {
        justify-content: flex-end;
      }
    }
  }


}


.price {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &__from {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  &__weight {
    font-size: 13px;
    line-height: 13px;
    color: $greyDark;
  }

}

.rangeControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.range {
  margin-top: 31px;
  width: 100%;
  height: 3px;
  box-shadow: none;
  border: none;
  background: $greyDark;
  border-radius: 0px;
  margin-left: 10px;
  margin-right: 10px;

  .noUi-connect {
    background: $blueDark;
  }

  .noUi-handle {
    outline: none !important;
    width: $white;
    width: 20px;
    height: 20px;
    border: 3px solid $blueDark;
    box-sizing: border-box;
    border-radius: 10px;
    right: -10px;
    top: -8.5px;
    cursor: pointer;

    &:before, &:after {
      display: none;
    }
  }
}

input[type="text"], input[type="number"] {
  display: block;
  border: 1px solid $greyDark;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 16px;
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: $greyDark;
  text-align: center;
  outline: none !important;
  width: 100%;

  &:hover, &:active, &:focus {
    border: 1px solid $greyDark !important;
    color: $greyDark !important;
  }

  @include sm-block {
    font-size: 16px;
    padding: 6px 12px;
  }
}

.rangeFilter {
  margin-bottom: 35px;

  &__minmax {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      color: $black;
      margin-right: 10px;

      &:last-of-type {
        margin-left: 10px;
      }
    }

    input {
    }
  }
}

.checkboxFilter {
  margin-bottom: 35px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  //height: calc(var(--vh, 1vh) * 100);
  left: 0;
  overflow: hidden;
  outline: 0;
  display: none;
  z-index: 100;
  background: rgba(0, 9, 35, 0.75);
  transition: $transition;
  opacity: 0;

  .modal {
    position: relative;
    transform: translateX(-100%);
    width: 80%;
    height: 100vh;
    background: #fff;
    transition: $transition;
    padding: 20px;
    z-index: 101;
    overflow: auto;

  }

  &.open {
    display: block;
    transition-delay: 0.2s;

    &.openGo {
      .modal {
        transform: translateX(0%);
        transition-delay: 0.2s;
      }
    }
  }

  &.openGo {
    opacity: 1;
    transition-delay: 0s
  }

  //&.open{
  //  display: block;
  //  opacity: 1;
  //  .modal{
  //  }
  //  &.openGo{
  //    opacity: 1;
  //    transition-delay: 0s;
  //    .modal{
  //      transform: translateX(0%);
  //      transition-delay: 0s;
  //    }
  //  }
  //  //&.closeGo{
  //  //  .modal{
  //  //    transform: translateX(0%);
  //  //  }
  //  //
  //  //}
  //}


}


.contentModal {
  &__title {
    display: flex;
    align-items: center;
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    justify-content: space-between;

    svg {
      padding: 5px;
      cursor: pointer;
      transition: $transition;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__body {
    margin-top: 24px;
  }
}

.dropdown {
  i {
    transition: $transition;
  }

  &.open {
    i {
      transform: rotate(180deg);

    }
  }
}

.headerMenu {
  ul {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 100%;

      a {
        padding: 6px 0px;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        color: #343434;
        position: relative;
        white-space: pre-wrap;
      }

      .dropdown {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        color: #343434;
        i {
          font-size: 28px;
          padding: 5px;
          cursor: pointer;
        }

        ul {
          display: none;
          width: 100%;
        }

      }
    }
  }

  [class^="mainHeader__"] {
    margin-top: 10px;
  }

  hr {
    margin: 10px 0 20px 0;
  }
}

.wrapPagin {
  @include col;
  @include size(12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  list-style: none;
  align-items: center;
  display: flex;
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $black;

  li {
    border-radius: 5px;
    transition: $transition;
    margin-right: 5px;

    i {
      font-weight: 600;
    }

    a {
      //$transition: $transition;
      text-decoration: none;
      font-weight: inherit;
      color: inherit;
      height: inherit;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 41px;

    &.active {
      color: $white;
      background-color: $blueDark;
    }

    &:hover {
      background-color: $blueDark;
      color: $white;
    }
  }
}

.story {
  @include sm-block {
    padding: 0;
    padding-bottom: 20px
  }
}

.topProduct {
  @include col;
  @include size(12);
  @include size-sm(12);

  .topTitle {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 33px 40px;
    border-radius:10px;
    @include sm-block {

      padding: 26px 10px 26px 10px;

    }

    .title {
      flex-grow: 1;
      max-width: 557px;
      text-align: left;
    }


    .button {
      margin-top: 25px;
      width: 275px;
    }

    .button--accentGold {
      border-color: $goldMain;
      color: $black;
    }

    img {
      cursor: pointer;
      align-self: center;
      margin-top: 20px;
    }
  }
}

.wrapProduct {
  .wrapName {
    @include col;
    @include size(12);
    margin-bottom: 37px;
  }

  .wrapperImage {
    @include col;
    @include size(4);
    @include size-sm(12);
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid $greyLight;

    .productImg {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .mainImg {
        width: 100%;

        a {
          display: block;

          img {
            width: 100%;
          }
        }
      }

      .additionalImg {
        width: 100%;
      }
    }

  }

  .wrapperInfo {
    @include col;
    @include size(8);
    @include size-sm(12);
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid $greyLight;
    overflow: hidden;
    display: flex;
    @include sm-block {
      flex-wrap: wrap;

    }

    .wrapperDescription {
      width: 52%;
      padding: 41px 36px;
      display: flex;
      flex-direction: column;
      @include sm-block {
        padding: 0;

      }


      .title {
        margin-bottom: 7px;
      }

      @include sm-block {
        width: 100%
      }
    }

    .wrapControls {
      width: 48%;
      padding: 19px 30px 30px 30px;
      background-color: $greyLight;
      cursor: pointer;
      @include sm-block {
        padding: 0;
      }

      .spanText {
        margin-bottom: 9px;
        margin-top: 22px;
        display: block;
      }

      .wrapProductsModel {
        margin-bottom: 22px;

        > .row {
          @include u-row-flex(25px);
        }

        .columnProductModel {
          @include u-col(25px);
          @include u-size(4, 12, 25px);
          @include lg-block {
            @include u-size(6, 12, 25px);
          }
        }

        .productModel {
          color: $greyDark;
          transition: $transition;
          background: $white;
          border-radius: 5px;
          padding: 6px 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 7px;

          .productTitle, .productWeight {
            font-family: TT Norms Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 12px;
          }

          .productPrice {
            transition: $transition;
            font-weight: 500;
          }

          > span {
            margin-bottom: 3px;

          }

          &--active {
            color: $blueDark;
            box-shadow: inset 0px 0px 2px 1px #0a2670;

            .productPrice {
              color: $blueDark;
            }
          }

          &:hover {
            color: $blueDark;
            box-shadow: inset 0px 0px 2px 1px #0a2670;

            .productPrice {
              color: $blueDark;
            }
          }
        }

      }

      @include sm-block {
        width: 100%
      }
    }
  }

  .product_desc {
    @include col;
    @include size(8);
    @include size-md(7);
    @include size-sm(12);

    > span {
      color: #343434;
      font-family: "TT Norms Pro";
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      display: block;
      margin-top: 27px;
    }

    > div {
      margin-top: 30px;
      color: #343434;
      font-family: "TT Norms Pro";
      font-size: 13px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .tryCoffee {
    @include col;
    @include size(4);
    @include size-md(5);
    @include size-sm(12);
    @include sm-block {
      margin-top: 30px;
    }
    margin-top: 27px;
    background: url(/catalog/view/theme/default/production/img/coffee1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 182px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    a {
      img {
        width: 50px;
      }

      display: block;
      max-width: 232px;
      color: #ffffff;
      font-family: "TT Norms Pro";
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }
}

.filter {
  .tryCoffee {
    @include sm-block {
      display: none;
    }
    margin-top: 47px;
    background: url(/catalog/view/theme/default/production/img/coffee1.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 288px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding-left: 30px;

    a {
      img {
        width: 73px;
      }

      display: block;
      max-width: 232px;
      color: #ffffff;
      font-family: "TT Norms Pro";
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }
}

.attrText {
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: $black;
  margin-top: 7px;
}

.attrRating {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 5px;
    margin-right: 3px;

    &:last-child {
      margin-right: 0px;
    }

    &.active {
      background-color: $blueDark;
    }

    &.nonActive {
      background-color: $greyMain;
    }
  }
}

.attributes {
  margin-top: 50px;
  &__ranges {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__rating {
      margin-right: 25px;
      margin-bottom: 18px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  &__box {

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      &__title {
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        color: $greyDark
      }

      &__doted {
        margin-right: 5px;
        margin-left: 10px;
        flex-grow: 1;
        height: 10px;
        border-bottom: 1px dashed $greyDark;
      }

      &__text {
        max-width: 120px;
        text-align: right;
      }

    }
  }
}

.additionalImg {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.optionWrap {
  > .row {

    @include u-row-flex(25px);
  }

  .wrapVal {
    span {
      text-align: center;
    }

    position: relative;
    @include u-col(25px);
    @include u-size(4, 12, 25px);
    @include lg-block {
      @include u-size(6, 12, 25px);
    }

    input {
      &:checked {
        & + label {
          color: $blueDark;
          box-shadow: inset 0px 0px 2px 1px #0a2670;

          img {
            background: $blueDark
          }
        }
      }

      &:disabled {
        & + label {
          filter: brightness(0.935);
          cursor: not-allowed !important;
          color: $greyDark !important;

          img {
            background: $greyDark !important;
          }
        }
      }
    }

    label {
      transition: $transition;
      cursor: pointer;
      border-radius: 5px;
      background: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 13px;
      color: $greyDark;
      padding: 6px 4px;
      justify-content: center;
      margin-bottom: 7px;

      img {
        background: $greyDark;
        margin-bottom: 6px;
        transition: $transition;
      }

      &:hover {
        color: $blueDark;
        box-shadow: inset 0px 0px 2px 1px #0a2670;

        img {
          background: $blueDark
        }

      }

    }

  }
}

.wrapProductButtons {
  .wprap_price {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: $black;
    display: block;
    margin-bottom: 25px;

    span {
      display: inline-block;

      &:first-child {
        margin-right: 13px;
      }
    }

    .oldPrice {
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 21px;
      text-decoration-line: line-through;
      color: #A0A0A0;
    }
  }


}


.wrapControlsProductButtons {
  display: flex;

  .button {
    padding-left: 20px;
    padding-right: 20px;
    @include sm-block {
      width: 100%;
    }
  }
}

.wrapQuantity {
  background: $white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;

  .control {
    height: 55px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid $white;
    transition: $transition;

    &:hover {
      border: 1px solid $greyMain;
    }
  }

  input {
    text-align: center;
    height: 55px;
    border: none;
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 0;
    width: 20px;
    color: $black;

    &:hover, &:focus {
      border: none !important;
      color: $black !important;
    }
  }

}

.customSort {
  .textBody {
    cursor: pointer;
    color: $greyDark;
    transition: $transition;

    &.active {
      color: $blueDark;
      font-size: 14px;;
      font-weight: 500;
    }

    &:hover {
      color: $blueDark;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.wrapSlider {
  @include col;
  @include size(12);
  @include size-sm(10);
  @include shift-sm-left(1);
  margin-top: 30px;
}

#mainSlider {
  .wrapSlider {
    user-select: none;
    .slider {
      position: relative;
      .swiper-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        //height: 100%;
        height: 225px;


        .itemSlide {
          transition: $transition;
          width: 24%;
          border-radius: 100%;
          background: $white;
          overflow: hidden;
          @include sm-block {
            width: 225px !important;
          }

          img {
            display: block;
            width: 100%;
          }
        }
      }

      .swiper-slide-next, .swiper-slide-prev {
        .itemSlide {
          width: 38%;
        }

      }

      .swiper-slide-active {
        .itemSlide {
          width: 100%;
        }

      }

      .swiper-wrapper {
        display: flex;
        align-items: stretch;
      }

      .button-prev, .button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
      }

      .button-prev {
        left: -22px;
      }

      .button-next {
        right: -22px;
      }
    }
  }
  .manufacturersCategories {
    user-select: none;
    position: relative;
    padding: 15px 20px 0 20px;
    margin-top: 45px;
    //background: $white;
    //background: transparent;
    background: #0a2670;
    opacity: 0.8;
    @include col;
    @include size(12);
    @include size-sm(10);
    @include shift-sm-left(1);
    .owl-item {
      .itemBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: auto;
        &:hover {
          border-bottom: 3px solid $greyDark;
        }
        img {
          width: 70px;
        }
        span {
          margin-top: auto;
          display: block;
          text-align: center;
          height: 36px;
          font-family: TT Norms Pro;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          color: #fff;
        }
        transition: $transition;
        border-bottom: 3px solid transparent;
      }
      &.center{
        .itemBlock{
          border-bottom: 3px solid  #fff;

        }
      }
    }
    .button-prev{
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      left: -22px;
      cursor: pointer;
    }
    .button-next{
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: -22px;
      cursor: pointer;
    }


  }

}

.wrapSliderInfo {
  @include col;
  @include size(4);
  @include shift-left(4);
  @include size-lg(6);
  @include shift-lg-left(3);
  @include size-sm(12);
  @include shift-sm-left(0);
  text-align: center;

  .swiper__productInfo {
    .textBody {
      * {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 25px !important;
        font-family: "TT Norms Pro"!important;
        font-style: normal!important;
      }
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 25px !important;
      font-family: "TT Norms Pro"!important;
      font-style: normal!important;
    }
    span {
      margin-top: 20px;
    }
    .title{
    }

    p {
      height: 75px;
      overflow: hidden;
      text-align: center;
      margin-top: 18px;


    }

    .button {
      margin-top: 30px;
    }
  }

}



.wrapSlideProduct {
  @include col;
  @include size(12);
  @include size-sm(12);

  .swiper {
    &-SlideProduct {
      overflow: hidden;
    }
  }

  .product {
    img {
    }

    &__control {
      @include from-to(1024px, 1130px) {
        flex-wrap: wrap;
        .price {
          margin-bottom: 20px;
        }
      }

      .price {
        margin-right: 24px;
        width: 100%;
      }

      .product__button {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  position: relative;

  .btnSP {
    position: absolute;
    top: 50%;
    z-index: 5;
    cursor: pointer;
    transform: translateY(-50%);

    &--prev {
      left: -100px;
    }

    &--next {
      right: -100px;
    }

    @include to(1320px) {
      &--prev {
        left: 0px;
      }
      &--next {
        right: 0px;
      }
    }
  }

  .button {
    //border-radius: 5px;
    //padding: 10px 0 !important;
    padding: 21px 0;
  }

  .product__control {
    align-items: flex-start;

    .price {
      height: 43px
    }
  }

  .product__description {
    height: 85px;
    overflow: hidden;
  }

}

.cart {


  &__title {
    @include col;
    @include size(12);
    @include size-sm(12);
    margin-bottom: 45px;

  }

  &__body {
    @include col;
    @include size(5);
    @include size-sm(12);

    .btnCheckout {
      width: 100%;
      margin-top: 28px;
    }
  }

  &__info {
    margin-top: -50px;
    @include sm-block {
      margin-top: 0px;
    }
    @include col;
    @include size(6);
    @include shift-left(1);
    @include size-sm(12);
    @include shift-sm-left(0);

    .wrapVip {
      img {
        width: 55%;
        height: auto;
      }

      padding: 10px 10px 34px 10px;
      background: #F8F8F8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        text-align: center;
        max-width: 350px;
      }

      span {
        margin-top: 11px;
        margin-bottom: 11px;
        display: block;
        max-width: 369px;
        text-align: center;
      }
    }

    .tryCoffee {
      padding: 15px;
      background: url(/catalog/view/theme/default/production/img/coffee1.png) center center / cover no-repeat;
      background-repeat: no-repeat;
      background-position: center center;
      display: flex;
      align-items: center;
      min-height: 182px;
      justify-content: center;
      border-radius: 10px;
      margin-top: 30px;

      a {
        display: flex;
        align-items: center;

        img {
          margin-right: 27px;
        }
      }

      .title {
        max-width: 234px;
      }
    }


  }

  &__itog {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      margin-right: 5px;
      color: $greyDark;
    }

    span {
      width: 160px;
      display: block;
      font-family: TT Norms Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      text-align: right;
      color: $black;
    }
  }


}

.empryCart {
  margin-bottom: 50px;
  width: 100%;
}

.cartProduct {
  padding-bottom: 13px;
  margin-bottom: 25px;
  border-bottom: 1px solid $greyMain;

  &__info {
    align-items: center;
    display: flex;
  }

  &__img {
    width: 80px;
    height: auto;
  }

  &__controls {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .wrapQuantity {
      overflow: hidden;
      border: 1px solid $greyMain;
      box-sizing: border-box;
      border-radius: 5px;
      height: 30px;
      margin-right: 0;
      margin-bottom: 14px;

      .control {
        width: 10px;
        border: none;
        cursor: pointer;

        i {
          font-size: 10px;
        }

        height: 30px;
      }

      input {
        height: 30px;
        line-height: 30px;
        font-size: 13px;
      }
    }
  }

  &__price {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    margin-bottom: 13px;

  }

  &__del {
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: $black;
    cursor: pointer;
  }

  &__description {
    padding-left: 40px;

    p {
      margin: 0px;
      margin-bottom: 3px;
      color: $greyDark;
    }
  }

  &__title {
    margin-bottom: 8px;
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $black;
    display: block;
  }

  &__description {
    width: calc(100% - 160px);
  }
}

.soderjit {
  .wrapVal {
    label {
      cursor: default;
      padding: 11px 9px;

      &:hover {
        color: #a0a0a0;

        img {
          background: #fff
        }

        span {
        }
      }

      img {
        background: #fff
      }
    }
  }

}

.wrapBreadcrumb {
  @include col;
  @include size(12);
  @include size-sm(12)
}

.checkout-checkout {
  .wrapTtitle {
    @include col;
    @include size(12);
    @include size-sm(12)
  }

  #formChekout {
    margin-top: 35px;
    @include col;
    @include size(12);
    @include size-sm(12);

    .guestInput, .addresInput {
      @include col;
      @include size(12);
      @include size-sm(12);
      padding-right: 47px;
    }

    .titleBlock {
      @include col;
      @include size(12);

      @include size-sm(12);
      margin-bottom: 18px;
      color: #343434;
      font-family: "TT Norms Pro";
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
    }

    .shipping_methods_wrap {
      @include col;
      @include size(12);
      @include size-sm(12);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      align-items: flex-start;

      .customInput {
        margin-right: 42px;
      }

      margin-bottom: 30px;
    }

    .payment_methods_wrap {
      @include col;
      @include size(12);
      @include size-sm(12);

      .customInput {
        margin-bottom: 17px;
      }
    }
  }
}

.inputGroup {
  margin-bottom: 35px;

  &__title {
    color: #343434;
    font-family: "TT Norms Pro";
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    display: block;
    margin-bottom: 10px;

  }

  &__input[type] {
    transition: $transition;
    border-radius: 0;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    color: #a0a0a0;
    text-align: left;
    padding: 12px 30px;
    width: 100%;
    font-family: "TT Norms Pro";
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;

    &::placeholder {
      color: #a0a0a0 !important;
    }

    &:hover {
      border: 1px solid #a0a0a0 !important;
    }

    &:focus {
      border: 1px solid #343434 !important;
      color: #343434 !important;
    }

  }

  &__input {
    &--error[type] {
      border: 1px solid #d80000 !important;
      color: #d80000 !important;

      &::placeholder {
        color: #d80000 !important;
      }

    }

    &--success[type] {
      color: #00c236;
      border: 1px solid #00c236;

    }
  }
}

#wrap_Confirm {
  margin-top: 83px;
  @include col;
  @include size(12);
  @include size-sm(12);

  .itog {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    span {
      &:first-child {
        color: #a0a0a0;
        font-family: "TT Norms Pro";
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;

      }
      &:nth-child(2){
        flex-grow: 1;
        border-bottom: 1px dashed #a0a0a0;
        margin-top: 12px;
        margin-left: 5px;
        margin-right: 5px;
      }

      &:last-child {
        color: #343434;
        font-family: "TT Norms Pro";
        font-size: 20px;
        font-weight: 500;
        line-height: 40px;

      }
    }
  }
}


input[type="submit"] {
  border: none !important;
}

#wrap_Payment {
  margin-top: 20px;
  @include col;
  @include size(12);
  @include size-sm(12);

  #button-confirm {
    width: auto;
    display: block;
  }
}

#orderSuccess {

  margin: auto;
  position: relative;
  border: 1px solid;
  border-radius: 30px;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 568px;

  .orderSuccessImg {
    position: absolute;
  }

  .orderSuccessImg--1 {
    bottom: 0;
    left: 0;
  }

  .orderSuccessImg--2 {
    bottom: 0;
    right: 0;
  }

  .mfp-close {
    border: none;
    opacity: 1;
    top: 60px;
    right: 37px;
  }

  h1 {
    text-align: center;
    margin-top: 47px;
    color: #2e56bf;
    font-family: "TT Norms Pro";
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    max-width: 400px;
    margin-bottom: 45px;
  }

  .text {
    a {
      color: inherit
    }

    text-align: center;
    // Style for "К вам на п"
    color: #000000;
    font-family: "TT Norms Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 67px;
  }
}

.emptyCategory {
  font-family: TT Norms Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #A0A0A0;
  @include col;
  @include size(12);
  @include size-sm(12);
}

button {
  border: none;
}

#backCall {
  overflow: hidden;
  border-radius: 30px;
  background-color: #ffffff;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 586px;
  display: none;
  flex-direction: column;
  padding: 47px 10px;

  .modalTitle {
    color: #343434;
    font-family: "TT Norms Pro";
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 45px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  .button {
    margin-top: 15px;
    width: 160px;
  }

  p {
    color: #a0a0a0;
    font-family: "Futura PT";
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    display: block;
    text-align: center;
    max-width: 246px;
    margin-top: 17px;
  }

  > img {
    position: absolute;
    bottom: 0;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }

  .inputGroup {
    max-width: 328px;
    width: 100%;
    margin-bottom: 25px;

  }


  .mfp-close {
    position: absolute;
    top: 60px;
    right: 37px;
    transition: $transition;
    opacity: 1;

    &:hover {
      opacity: .6;
    }
    @include sm-block{
      top: 11px;
      right: 10px;
    }
  }

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 328px;
    width: 100%;
  }

  @include sm-block{
    padding: 24px;
    .modalTitle{
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-top: 41px;
      margin-bottom: 20px;
    }
  }
}

.mfp-content {
  #backCall {
    display: flex !important;
  }
}

#openFrom{
  cursor: pointer;
}

#wrapForm {
  overflow: hidden;
  border-radius: 30px;
  background-color: #ffffff;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 586px;
  display: none;
  flex-direction: column;
  padding: 47px 10px;

  .modalTitle {
    color: #343434;
    font-family: "TT Norms Pro";
    font-size: 40px;
    font-weight: 500;
    line-height: 50px;
    margin-bottom: 45px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  .button {
    margin-top: 15px;
    width: 160px;
  }

  p {
    color: #a0a0a0;
    font-family: "Futura PT";
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    display: block;
    text-align: center;
    max-width: 246px;
    margin-top: 17px;
  }

  > img {
    position: absolute;
    bottom: 0;

    &:first-of-type {
      left: 0;
    }

    &:last-of-type {
      right: 0;
    }
  }

  .inputGroup {
    max-width: 328px;
    width: 100%;
    margin-bottom: 25px;

  }


  .mfp-close {
    position: absolute;
    top: 60px;
    right: 37px;
    transition: $transition;
    opacity: 1;

    &:hover {
      opacity: .6;
    }
    @include sm-block{
      top: 11px;
      right: 10px;
    }
  }

  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 328px;
    width: 100%;
  }

  @include sm-block{
    padding: 24px;
    .modalTitle{
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      margin-top: 41px;
      margin-bottom: 20px;
    }
  }
}

.mfp-content {
  #wrapForm {
    display: flex !important;
  }
}


#ques {
@include sm-block{
  padding: 24px;
}

}

.wrapqqq {
  position: relative;
  z-index: 1;
  @include sm-block{
    &:before{
      z-index: 0;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #fff;
      position: absolute;
      opacity: 0.5;
    }
  }
}

.ques {
  transition: $transition;
  width: 100%;
  background: #FFFFFF;
  border-radius: 30px;
  position: relative;
  max-width: 1269px;
  min-height: 630px;
  border: 1px solid;
  margin: auto;
  padding: 44px 44px 122px 180px;

  &.prod {
    padding: 44px;

  }

  .productErr {
    margin-top: 50px;
    @include sm-block {
      margin-top: 24px;

    }
  }

  .mfp-close {
    border: none;
    opacity: 1;
    top: 46px;
    right: 55px;
    @include sm-block {
      top: 10px;
      right: 10px;

    }
  }

  &__bar {
    position: relative;
    max-width: 410px;
    height: 6px;
    border-radius: 3px;
    background: $goldLight;


    &__scroll {
      transition: $transition;
      height: 6px;
      border-radius: 3px;
      background: $goldMain;
    }
  }

  &__step {
    color: #a0a0a0;
    font-family: "TT Norms Pro";
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    display: block;
    margin-top: 40px;

  }

  &__err {
    font-family: "TT Norms Pro";
    font-size: 13px;
    font-weight: 500;
    color: #d80000;
    margin-bottom: 20px;

    &--active {
    }
  }

  &__back {
    display: block;
    margin-top: 40px;
    font-family: TT Norms Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #A0A0A0;
    padding: 10px 10px 10px 0;
    cursor: pointer;
    @include sm-block {
      margin-top: 20px;
      background: #fff;
      border-radius: 5px;
      text-align: center;
      box-shadow: 0 7px 12px 0px #0000004a;
      padding: 14px 10px 13px 10px;
      z-index: 1;
      position: relative;
    }
  }

  .button {
    @include sm-block {
      text-align: center;
      width: 100%;
      z-index: 1;
      position: relative;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }


  @include sm-block {
    padding: 24px;
    min-height: auto;
  }

  .wrapProduct {
    .wrapName {
      text-align: center
    }

  }
}

.wrap_sliderQues {
  margin-top: 24px;
  margin-bottom: 68px;
  overflow: hidden;
  transition: $transition;
  height: 0;
  position: relative;
  @include sm-block {
    margin-top: 12px;
    margin-bottom: 24px;
  }
}

.slideQuest {
  position: absolute;
  top: 0;

  &__title {
    color: #343434;
    font-family: "TT Norms Pro";
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 40px;
  }


  .customInput {
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0px;
    }

    input {
      + label {
        svg {
        }

        span {
        }
      }
    }

    [type="checkbox"] {
      + label {
        svg {
          > rect {
          }

          > path {
            display: none
          }
        }

        &:hover {
          svg {
            > rect {

            }
          }

        }
      }

      &:checked {
        + label {
          svg {
            > rect {

            }

            > path {
              display: block
            }
          }

          &:hover {
            svg {
              > rect {

              }
            }

          }
        }
      }
    }

    [type="radio"] {
      &.checkbox {
        + label {
          svg {
            > rect {
            }

            > path {
              display: none
            }
          }

          &:hover {
            svg {
              > rect {
                fill: $white;
                stroke: $blueDark
              }
            }

          }
        }

        &:checked {
          + label {
            svg {
              > rect {
                transition: $transition;
                fill: $blueDark;
                stroke: $blueDark
              }

              > path {
                display: block
              }
            }

            &:hover {
              svg {
                > rect {
                  fill: $blueMain;
                  stroke: $blueMain;
                }
              }

            }
          }
        }
      }
    }


  }


}

.switch-enter-to, .switch-leave-active {
  transition: $transition;
}


.switch-leave-to {
  opacity: 0;
}

.switch-enter {
  opacity: 0;
}

.switch-enter-to {
  position: absolute;
}

#productWraper {
  .productErr {

  }
}

.popupArrow {
  z-index: 99999999999999999999999999999999;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 40px);
  background: transparent;

  &--right {
    right: -180px;
  }

  &--left {
    left: -180px;
    transform: rotate(180deg);
  }

  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

.breadcrumb {
  li{
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    a{
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
    &:last-child{
      a{

        color: #343434
      }
    }
  }
  @include sm-block {
    margin-top: 80px;
  }
}

.contacts__wrap {
  min-height: auto;
  display: block;
}

.contacts__block {
  @include sm-block {
    min-height: auto;
    display: block;
  }

  p {
    display: block;
  }

  overflow: hidden;
}
.contacts{
  @include sm-block{
    margin-top: 25px;
    .title{
      margin-bottom:25px;

    }
  }
}

.optionError {
  padding: 10px 20px;
  border: 1px solid #d80000;
  color: #d80000;
  font-family: "TT Norms Pro";
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
  border-radius: 5px;
}

.mfp-content {


  .story {
    background: #fff;
    padding: 0;
    border-radius: 24px;
    overflow: hidden;

    .title {
      display: block;
      margin-top: 50px;
      text-align: center;
    }

    .story__close {
      display: none;
    }

    .mfp-close {
      border: none;
      opacity: 1;
      top: 46px;
      right: 55px;
      position: absolute;
    }

    max-width: 1000px;
    position: relative;
    margin: auto;

    &__body {
      display: block;
      padding-bottom: 30px;
    }
  }
}

.rowCheckout {
  .col1, .col2 {
    @include col;
    @include size(6);
    @include size-sm(12);


  }

  .col1 {

  }

  .col2 {
   .wrapper{
     position: sticky;
     top: 110px;
   }
  }
}
.callLink{
  cursor: pointer;
}
.story{
  padding: 0;
}
.information{
  padding-top: 0px;
}
.newsListTitle{
  .title{
    margin-bottom: 37px;
  }
}
.blog-page {
  padding: 0px 0;
}

#mainSlider {
  @include sm-block {
    .homeSliderSubTitle{
      .title{
        display: none;
      }
    }
    .swiper__productInfo {
      .textBody {
        display: none;
      }
      .title{
        display: block;
      }
      .button{
        //display: block;
      }
    }
  }

}
.mobileHidden{
  @include sm-block{
    display: none;
  }
}
.descktopHidden{
  display: none;
  @include sm-block{
    display: block;
  }

}
.lineMobile{
  @include col;
  @include size-sm(12);
  border: 1px solid #ebeff2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f8f8f8;
  img{
    width: 60px;
    margin-right: 15px;
  }
}
#mainSlider{
    @include sm-block{
      .button-prev{
        left: -32px !important;
      }
      .button-next{
        right: -32px !important;
      }
    }
}
input, textarea, select {
  -webkit-appearance: none;
}
#wrap_Payment{
  margin-bottom: 15px;
  .buttons{
    display: flex;
    justify-content: flex-end;
    #button-confirm{

    }
  }

}