.blog-page {
    padding: 50px 0;
    .container {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
    &__head {
        padding: 0 94px;
        padding-top: 43px;
        padding-bottom: 73px;
        margin-bottom: 62px;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 100%; }
    &__date {
        @include Medium;
        font-size: 16px;
        line-height: 19px;
        color: #FDFDFF;
        margin-bottom: 37px;
        display: block; }
    &__title {
        @include Medium;
        font-size: 40px;
        line-height: 50px;
        color: #FDFDFF;
        text-align: left; }
    &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: "TT Norms Pro" !important;
        line-height: 21px;
        p, li {
            @include Regular;
            font-size: 13px;
            line-height: 21px;
            color: #343434;
            margin: 0;
            margin-bottom: 20px; }
        h2 {
            @include Medium;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px; }
        ul {
            padding: 0;
            margin: 0;
            margin-bottom: 20px;
            li {
                list-style: none;
                padding: 0;
                margin-bottom: 5px; } } } }

@media (max-width: 768px) {
    .blog-page {
        &__head {
            padding: 0 20px;
            padding-top: 43px;
            padding-bottom: 73px; }
        &__date {
            margin-bottom: 10px; }
        &__title {
            font-size: 20px;
            line-height: 22px; } } }
