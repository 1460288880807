.breadcrumb {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    li {
        list-style: none;
        position: relative;
        padding-right: 15px;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        &::before {
            content: "-";
            font-family: TT Norms Pro;
            font-style: normal;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
            color: #A0A0A0;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px; }


        &:last-child {
            padding-left: 0;
            a {
                color: #343434; }
            &::before {
                display: none; } } }
    a, span, p {
        @include Regular;
        color: #A0A0A0;
        font-size: 15px; }
    span {
        margin: 0 4px; }
    p {
        color: #343434; } }
