.actual {
    padding-top: 56px;
    margin-bottom: 30px;
    .title {
        margin-bottom: 40px; }
    &__items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        position: relative;
        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 46px;
            height: 46px;
            border-radius: 100px;
            background: #fff url(../img/icons/slider-prev-small.svg) center no-repeat;
            background-size: 60%;
            filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.06));
            font-size: 0;
            border: none;
            cursor: pointer;
            z-index: 5;
            &.slick-prev {
                left: calc(-30px - 46px);
                background: #fff url(../img/icons/slider-prev-small.svg) center no-repeat; }
            &.slick-next {
                right: calc(-30px - 46px);
                background: #fff url(../img/icons/slider-next-small.svg) center no-repeat; } }
        .slick-list {
            overflow: hidden; }
        .slick-track {
            min-width: 100% !important;
            display: flex !important; } }
    &__item {
        width: 100% !important;
        max-width: 255px;
        display: flex;
        flex-direction: column;
        margin-right: 30px;
        background: #fff;
        &:last-child {
            margin-right: 0; } }
    &__img {
        width: 100%;
        height: 192px; }
    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 22px; }
    &__title {
        @include Medium;
        font-size: 18px;
        line-height: 25px;
        color: #343434;
        margin-bottom: 27px;
        padding-left: 28px;
        padding-right: 8px; }
    &__desc {
        @include Regular;
        font-size: 13px;
        color: #343434;
        padding-left: 28px;
        padding-right: 8px;
        margin: 0;
        margin-bottom: 21px; }
    &__total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        a {
            height: 65px;
            width: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #0A2670;
            color: #ffffff;
            @include Medium;
            font-size: 16px; } }
    &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 28px;
        span {
            @include Medium;
            font-size: 20px;
            color: #343434; }
        p {
            @include Regular;
            font-size: 13px;
            color: #A0A0A0;
            margin: 0; } } }

@media screen and ( max-width: 1250px ) {
    .actual {
        &__items {
            .slick-list {
                margin: 0 60px; }
            .slick-arrow {
                &.slick-prev {
                    left: 0; }
                &.slick-next {
                    right: 0; } } } } }
