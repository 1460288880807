.not-found-cart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    h1 {
        @include Medium;
        font-size: 40px;
        color: #343434;
        margin-bottom: 26px; }
    p {
        @include Medium;
        font-size: 18px;
        line-height: 25px;
        color: #343434;
        margin: 0;
        margin-bottom: 50px; } }

