$transition: 0.2s linear all;
$blueMain: #2E56BF;
$blueDark: #0a2670;
$bluerDoubleDark: #06184a;
$black: #343434;
$white: #ffffff;
$goldLight: #fff3dd;
$goldMain: #F5D69F;
$goldDark: #ebbf71;
$greyLight: #f8f8f8;
$greyDark: #a0a0a0;
$greyMain: #eeeeee;
.story {
    padding: 50px 0;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center; }
    &__close {
        display: inline-flex;
        padding: 14px 37px 13px 37px;
        font-family: TT Norms Pro;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        border-radius: 5px;
        transition: $transition;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        color: $white;
        background: $blueDark;
        border: none;
        &:hover {
            background: $blueMain; }
        &:active {
            background: $bluerDoubleDark; } }

    &__head {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: url(../img/story/head.png) center no-repeat;
        background-size: cover;
        height: 306px;
        position: relative; }
    &__title {
        @include Medium;
        font-size: 30px;
        line-height: 40px;
        color: #FFFFFF;
        text-align: center;
        margin: 0;
        margin-bottom: 39px;
        max-width: 370px; }
    &__btn {
        padding: 12px 69px;
        background: #F5D69F;
        border-radius: 5px;
        @include Medium;
        font-size: 16px;
        color: #343434;
        border: none; }
    &__ico {
        position: absolute;
        width: 20px;
        height: 20px;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%); }
    &__body {
        display: none;
        flex-direction: column;
        align-items: center;
        background: #fff;
        padding: 0 50px;
        padding-top: 87px;
        padding-bottom: 94px;
        width: 100%; }
    &__block {
        display: flex;
        margin-bottom: 67px;
        width: 100%;
        position: relative;
        &-1, &-3 {
            .story__textbox {
                margin-right: 40px; } }
        &-1 {
            margin-bottom: 100px;
            &::before {
                content: "";
                width: 295px;
                height: 108px;
                background: url(../img/story/line-1.png) center no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%); }
            .story__imgbox img {
                top: -55px;
                left: -40px; } }
        &-2 {
            margin-bottom: 140px;
            &::before {
                content: "";
                width: 295px;
                height: 108px;
                background: url(../img/story/line-2.png) center no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -125px;
                left: 50%;
                transform: translateX(-50%); }
            .story__imgbox img {
                top: -40px;
                right: 10px; }
            .story__textbox {
                margin-left: 40px; } }
        &-3 {
            .story__imgbox img {
                top: -40px;
                left: -40px; } } }
    &__imgbox {
        flex: 1;
        position: relative;
        img {
            position: absolute; } }
    &__textbox {
        flex: 1;
        max-width: 455px;
        h3 {
            @include Medium;
            font-size: 30px;
            line-height: 40px;
            color: #343434;
            margin: 0;
            margin-bottom: 42px; }
        p {
            @include Regular;
            font-size: 12px;
            line-height: 21px;
            color: #343434;
            margin: 0;
            mark {
                background: #0A2670;
                color: #fff;
                padding: 2px 3px; } } } }

@media screen and ( max-width: 996px ) {
    .story {
        &__imgbox {
            max-width: 100%;
            img {
                width: 100%;
                position: static; } }
        &__block {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 50px !important;
            &-2 {
                flex-direction: column-reverse !important; }
            &::before {
               display: none !important; } }
        &__textbox {
            max-width: 100%;
            margin: 0 !important; } } }

@media screen and ( max-width: 780px ) {
    .story {
        &__body {
            padding: 5px; }
        &__textbox {
            h3 {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 25px; } } } }
