.not-found {
    margin-bottom: 70px;
    .container {
        padding-top:  37px; }
    &__content {
        padding-top: 82px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            @include Medium;
            font-size: 40px;
            line-height: 50px;
            color: #000000;
            margin-bottom: 9px; }
        p {
            @include Medium;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            margin-bottom: 62px; } }
    &__imgs {
        width: 100%;
        position: relative;
        max-width: 780px;
        margin-bottom: 111px; }
    &__404 {
        width: 100%; }
    &__coffee {
        position: absolute;
        left: 50%;
        top: calc(100% - 35px);
        transform: translate(-50%, -50%); } }


