@mixin Bold {
    font-family: 'TT Norms Pro', sans-serif;
    font-weight: 800; }
@mixin Medium {
    font-family: 'TT Norms Pro', sans-serif;
    font-weight: 500; }
@mixin Regular {
    font-family: 'TT Norms Pro', sans-serif;
    font-weight: 400; }
@mixin Light {
    font-family: 'TT Norms Pro', sans-serif;
    font-weight: 300; }

body {
    background: #FDFDFF; }
