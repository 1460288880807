.contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 116px;
    h1 {
        @include Medium;
        font-size: 40px;
        line-height: 50px;
        color: #343434;
        margin-bottom: 77px; }
    &__body {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 45px;
        width: 100%; }
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start; }
    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        span {
            @include Medium;
            font-size: 18px;
            line-height: 25px;
            color: #000000;
            margin: 0;
            margin-bottom: 19px; }
        p {
            @include Regular;
            font-size: 13px;
            line-height: 21px;
            color: #000000;
            max-width: 272px;
            margin: 0; } }
    #map {

        height: 467px;
        width: 100%; } }

@media screen and ( max-width: 996px ) {
    .contacts {
        &__body {
            grid-template-columns: 1fr 1fr; }
        &__wrap {
            min-height: 300px;
            height: 100%; } } }

@media screen and ( max-width: 480px ) {
    .contacts {
        &__body {
            grid-template-columns: 1fr; } } }
